import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import useMediaQuery from '@mui/material/useMediaQuery';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

import Place from './Place'

export default function Home() {

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{textAlign: `left`}}>
          <Grid item xs={12} md={12}>
            <h1 style={{marginBottom: '0'}}>Brilliant events</h1>
          </Grid>
          <Grid item xs={12} md={12}>
            <h3>Locații pentru evenimente</h3>
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{textAlign: `${matches ? 'right' : 'center'}`}}>
          <Grid item xs={12} md={12}>
            <Place
              name="Brilliant Parc"
              img="parc"
              link="https://brilliantparc.ro/"/>
          </Grid>
          <Grid item xs={6} md={6}>
            <Place
              name="Brilliant City"
              img="city"
              link="https://brilliantcity.ro/"/>
          </Grid>
          <Grid item xs={6} md={6}>
            <Place
              name="Aria by Brilliant"
              img="aria"
              link="https://ariabybrilliant.ro/restaurant/"/>
          </Grid>
        </Grid>
        <br/>
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" className="footer" style={{textAlign: `${matches ? 'left' : 'center'}`}}>
          <Grid item xs={6} md={4}>
            <img src="logo.jpeg" style={{maxWidth: '150px'}}/>
          </Grid>
          <Grid item xs={6} md={8}>
            <a href="https://www.instagram.com/brillianteventsofficial/"><InstagramIcon /></a> <a href="https://www.facebook.com/Brillianteventsofficial/"><FacebookIcon /></a><br/>
            <a href="tel:+40725998346">+4 0725 998 346</a><br/>
            <a href="mailto:office@brilliantparc.ro?subject=Informatii rezervare&body=Buna ziua, %0d%0a%0d%0aAm dori sa facem o rezervare ... %0d%0a%0d%0aMultumesc,%0d%0aO zi buna!">office@brilliantparc.ro</a><br/>
            <a href="https://www.google.ro/maps/place/Brilliant+Park/@47.1794334,23.0466488,17z/">Strada George Coșbuc 1, Zalău 450043</a><br/>
          </Grid>
        </Grid>
        <br/>
      </Container>
    </React.Fragment>
  );
}
