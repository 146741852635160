import * as React from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    paperContainer: {
        height: '45vh',
        backgroundImage: props => `url(/img/${props.img}.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: '#fff',
        fontWeight: 600,
        padding: '2%',
        bottom: 0,
        paddingTop: '38vh',
        boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.2)',
        "&:hover, &:focus": {
          boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.0)'
        },
    }
});

export default function Place(props) {
  const { paperContainer } = useStyles(props);
  return (
    <a href={`${props.link}`} target="_blank">
      <Paper className={paperContainer}>
          {props.name}
      </Paper>
    </a>
  );
}
